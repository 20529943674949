import React from "react";
// import ProductPage from "../ProductsPage/ProductPage.jsx";
import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/power-tools/brands.png"

import p1 from "assets/img/products/power-tools/1.jpg";
import p2 from "assets/img/products/power-tools/2.jpg";
import p3 from "assets/img/products/power-tools/3.jpg";
import p4 from "assets/img/products/power-tools/4.jpg";
import p5 from "assets/img/products/power-tools/5.jpg";
import p6 from "assets/img/products/power-tools/6.jpg";
import p7 from "assets/img/products/power-tools/7.jpg";
import p8 from "assets/img/products/power-tools/8.jpg";
import p9 from "assets/img/products/power-tools/9.jpg";
import p10 from "assets/img/products/power-tools/10.jpg";
import p11 from "assets/img/products/power-tools/11.jpg";
import p12 from "assets/img/products/power-tools/12.png";
import p13 from "assets/img/products/power-tools/13.jpg";
import p14 from "assets/img/products/power-tools/14.jpg";
import p15 from "assets/img/products/power-tools/15.jpg";
import p16 from "assets/img/products/power-tools/16.png";
import p17 from "assets/img/products/power-tools/17.jpg";
import p18 from "assets/img/products/power-tools/18.jpg";
import p19 from "assets/img/products/power-tools/19.jpg";
import p20 from "assets/img/products/power-tools/20.jpg";
import p21 from "assets/img/products/power-tools/21.png";
import p22 from "assets/img/products/power-tools/22.jpg";
import p23 from "assets/img/products/power-tools/23.jpg";

import s1 from "assets/img/products/power-tools/slider/1.jpg";
import s2 from "assets/img/products/power-tools/slider/2.jpg";
import s3 from "assets/img/products/power-tools/slider/3.jpg";

export default () => (
  <ProductPage prod="Power Tools" url="power-tools" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
  { img: s1 },
  { img: s2 },
  { img: s3 }
]

const products = [
  { name: 'Knockouts', img: p1 },
  { name: 'Batteries', img: p2 },
  { name: 'Routers', img: p3 },
  { name: 'Die Grinder', img: p4 },
  { name: 'Rotary Hammer', img: p5 },
  { name: 'Demolition Hammer', img: p6 },
  { name: 'Chop Saw', img: p7 },
  { name: 'Angle Grinder', img: p8 },
  { name: 'Impact Wrenches', img: p9 },
  { name: 'Tools Storage', img: p10 },
  { name: 'Sanders', img: p11 },
  { name: 'Compact Hammers', img: p12 },
  { name: 'Jigsaw Machine', img: p13 },
  { name: 'Cordless Drill', img: p14 },
  { name: 'Blower', img: p15 },
  { name: 'Screw Driver', img: p16 },
  { name: 'Spray Paint System', img: p17 },
  { name: 'Glue Gun', img: p18 },
  { name: 'Saw', img: p19 },
  { name: 'Percussion Drills', img: p20 },
  { name: 'Hot Air Gun', img: p21 },
  { name: 'Circular Saw', img: p22 },
  { name: 'Angle Polisher', img: p23 },
]